$(document).on('click', '.js-ajax-remove-item', (e) => {
  e.preventDefault()

  const
  URL = $(e.currentTarget).data('url')
  CONTAINER_ID = $(e.currentTarget).data('container'),
  CONTAINER_EL = $('#' + CONTAINER_ID),
  CALLBACKS = $(e.currentTarget).data('callbacks'),
  CONFIRMATION = $(e.currentTarget).data('confirmation'),
  CONFIRMATION_MSG = getParameter($(e.currentTarget).data('confirmationmessage'), 'Are you sure you want to delete this item?'),
  CONFIRMATION_BUTTON = getParameter($(e.currentTarget).data('confirmbutton'), 'OK')
  CONFIRMATION_CANCEL_BUTTON = getParameter($(e.currentTarget).data('cancelbutton'), 'Cancel')
  CONFIRMATION_TITLE = getParameter($(e.currentTarget).data('confirmationtitle'), 'Confirm');
  CONFIRMATION_ICON = $(e.currentTarget).data('confirmationicon');
  let
    flashMsg = null

  const ajaxCall = () => {
    $.ajax({
      url: URL,
      method: 'DELETE',
      beforeSend: () => {
        blockElement(CONTAINER_EL)
      },
      success: response => {
        if (response.reload) {
          location.reload()
        } else if (response.goToUrl) {
          window.location.href = response.goToUrl
        } else if (response.success) {
            if (CONTAINER_EL.length) {
                if (response.responseView){
                    CONTAINER_EL.replaceWith(response.responseView);
                } else{
                    CONTAINER_EL.fadeOut(300, () => {
                        CONTAINER_EL.remove()
                    })
                }

          }
          const methodsArr = CALLBACKS ? CALLBACKS.split(',') : []
          methodsArr.forEach(el => {
            const FUNC = window[el]
            FUNC(response)
          })
        }
        if (response.hasOwnProperty('flashMessage')) {
          flashMsg = response.flashMessage
        }

        reinitTooltip()
      },
      fail: (xhr, textStatus, errorThrown) => {
        openFoundationMessage(
          'An error has occurred , Please contact our office!'
        )
      },
      complete: () => {
        unblockElement($('#' + CONTAINER_ID))
        if (flashMsg) {
          FlashMessage.showShortMessage(flashMsg)
        }
      }
    })
  }
  if (CONFIRMATION) {
    confirmation_dialog(ajaxCall, CONFIRMATION_TITLE, CONFIRMATION_MSG, CONFIRMATION_BUTTON, CONFIRMATION_CANCEL_BUTTON, CONFIRMATION_ICON)
  } else {
    ajaxCall()
  }
})

/**
 * Available data properties
 * data-url | REQUIRED | : ajax url to be called
 * data-container : dom element id where to display result
 * data-modal: display result in modal popup
 * data-callbacks: list of js methods to call after ajax (comma separated list)
 * data-confirmation: display confirmation message (1-display)
 * data-confirmationmessage: confirmation message to be displayed if data-confirmation=1, def value 'Are you sure you want to perform selected action?'
 * data-confirmationbutton: Yes/Ok button label for confirmation dialog if data-confirmation=1, def value 'Submit'
 * data-confirmationtitle: confirmation title to be displayed if data-confirmation=1, def value 'Confirm'
 * data-append | if param exists, 1=append to the top of container element, 2=append to the bottom of container element, 3(default)=replace container element with result view,4=replace inner html of container with response
 * data-disable-buttons | disable buttons and prevent multi requests sends
 * 
 */

 $(document).on('click', ".js-ajax-element-event", function (event) {
  event.preventDefault();

  var eventElement = $(this);
  var url = $(this).data('url');
  var containerId = $(this).data('container');
  var containerElement
  if ($(this).data('container-class')) {
      containerElement = $('.' + containerId);
  } else {
      containerElement = $('#' + containerId);
  }
  var aftercontainerId = $(this).data('aftercontainer');
  var aftercontainerElement = $('#' + aftercontainerId);
  var callbacks = $(this).data('callbacks');
  var confirmation = $(this).data('confirmation');
  var modal = $(this).data('modal');
  var noLoadingCircle = $(this).data('noloadingcircle');
  var confirmationMessage = getParameter($(this).data('confirmationmessage'),'Are you sure you want to perform selected action?');
  var confirmationButton = getParameter($(this).data('confirmationbutton'),'Submit');
  var cancelButton = getParameter($(this).data('cancelbutton'),'Cancel');
  var confirmationTitle = getParameter($(this).data('confirmationtitle'),'Confirm');
  var append = getParameter($(this).data('append'),3);
  var scroll = $(this).data('scroll');
  if (undefined === scroll) {
      scroll = 1;
  }
  var disableBtnsClass = $(this).data('disable-buttons');
  var disableBtns = $('.' + disableBtnsClass);
  var scrollToObject = $(this).data('scrolltoelement');
  var method = $(this).data('method');
  if (undefined === method) {
      method = 'GET';
  }
  var flashMessage = null;
  let stopPropagation = eventElement.data('stop-propagation');
  if (stopPropagation) {
    event.stopPropagation();
  }

  var target = $(event.target);
  if (eventElement.data('check-target') && !target.hasClass('js-click-target')) {
      return;
  }
  $('.js-ajax-element-event-load-content').remove();
  if (eventElement.data('check-active') && eventElement.hasClass('active')) {
      $(eventElement.removeClass('active'));
  } else {
      var ajaxCall = function () {
          $.ajax({
              url: url,
              method: method,
              beforeSend: function () {
                  if(disableBtns) {
                      disableBtns.prop('disabled', true);
                  }
                  if(!noLoadingCircle) {
                    if (containerId) {
                      blockElement(containerElement);
                    } else {
                      blockElement(eventElement);
                    }
                  }
              },
              success: function (response) {
                  if (response.reload){
                      location.reload();
                  } else if (response.goToUrl) {
                      window.location.href = response.goToUrl;
                  } else if (response.success){
                      if (response.responseView && containerElement.length){
                          switch (append){
                              case 1:
                                  containerElement.prepend(response.responseView);
                                  break;
                              case 2:
                                  containerElement.append(response.responseView);
                                  break;
                              case 3:
                                  containerElement.replaceWith(response.responseView);
                                  break;
                              case 4:
                                  containerElement.html(response.responseView);
                                  break;

                          }
                          containerElement.find(".js-ajaxLoadBox").each(function (i, obj) {
                              fillInvisibleContent($(this));
                          });
                          var element = $("#" + scrollToObject);
                          if (scroll) {
                              if (scrollToObject && element && element.length) {
                                  scrollToElement(element);
                              } else {
                                  scrollToElement(containerElement);
                              }
                          }
                      } else if (response.responseView && modal){
                          openFoundationMessage(response.responseView);
                      }else if (response.responseView && aftercontainerElement) {
                          aftercontainerElement.after(response.responseView);
                      }
                      else if (response.responseView) {
                          eventElement.after(response.responseView);
                      }

                      var methodsArr = [];
                      if (callbacks) {
                          methodsArr = callbacks.split(",");
                      }
                      for (var i = 0; i < methodsArr.length; i++) {
                          var func = window[methodsArr[i]];
                          var retCallback = func(response);
                      }

                  }
                  if (response.hasOwnProperty("flashMessage")){
                      flashMessage = response.flashMessage;
                  }

                  reinitTooltip();
                  $(document).foundation();
                  eventElement.addClass('active');
              },
              fail: function (xhr, textStatus, errorThrown) {
                  openFoundationMessage("An error has occurred , Please contact our office!");
              },
              complete: function () {
                if(!noLoadingCircle) {
                  if (containerId) {
                    containerElement.unblock();
                  } else {
                    eventElement.unblock();
                  }
                }
                  if (flashMessage) {
                      FlashMessage.showShortMessage(flashMessage);
                  }
              }
          });
      }
      if (confirmation) {
          var confirmIcon = $(this).data('confirmationicon')
          if (confirmIcon) {
              confirmation_dialog(ajaxCall, confirmationTitle, confirmationMessage, confirmationButton, 'Cancel', confirmIcon);
          } else {
              confirmation_dialog(ajaxCall, confirmationTitle, confirmationMessage, confirmationButton);
          }
      } else {
          ajaxCall();
      }
  }
});

$(document).on('click', '.js-ajax-switch-element-event', e => {
  const
    URL = $(e.currentTarget).data('url')
  const CONTAINER_ID = $(e.currentTarget).data('container')
  const CALLBACKS = $(e.currentTarget).data('callbacks')
  const CONFIRMATION = $(e.currentTarget).data('confirmation')
  const CONFIRMATION_MSG = getParameter($(e.currentTarget).data('confirmationmessage'), 'Are you sure you want to close this item?')
  const CONFIRMATION_BTN = getParameter($(e.currentTarget).data('confirmationbutton'), 'Submit')
  const METHOD = $(e.currentTarget).data('method')
  let
    containerEl = $('#' + CONTAINER_ID)
  let flashMessage = null

  const ajaxCall = () => {
    $.ajax({
      url: URL,
      method: METHOD,
      beforeSend: () => {
        blockElement(containerEl)
      },
      success: response => {
        if (response.reload) {
          location.reload()
        } else if (response.goToUrl) {
          window.location.href = response.goToUrl
        } else if (response.success) {
          if (response.responseView) {
            containerEl.replaceWith(response.responseView)
            containerEl = $('#' + CONTAINER_ID)
            containerEl.find('.js-ajaxLoadBox').each((i, obj) => {
              fillInvisibleContent($(obj))
            })
          }
          const methodsArr = CALLBACKS ? CALLBACKS.split(',') : []
          methodsArr.forEach(el => {
            const FUNC = window[el]; const RET_CALLBACK = FUNC(response)
          })
        }
        if (response.hasOwnProperty('flashMessage')) {
          flashMessage = response.flashMessage
        }

        reinitTooltip()
      },
      fail: (xhr, textStatus, errorThrown) => {
        openFoundationMessage(
          'An error has occurred , Please contact our office!'
        )
      },
      complete: () => {
        unblockElement($('#' + CONTAINER_ID))
        if (flashMessage) {
          FlashMessage.showShortMessage(flashMessage)
        }
      }
    })
  }
  if (CONFIRMATION) {
    confirmation_dialog(ajaxCall, 'Confirm', CONFIRMATION_MSG, CONFIRMATION_BTN)
  } else {
    ajaxCall()
  }
})

$(document).on('click', '.js-ajax-submit', e => {
  e.preventDefault()

  const
    OBJ = $(e.currentTarget)
  const CONFIRMATION = OBJ.data('confirmtext')
  const BTN_TEXT = OBJ.data('buttontext')
  const CONTAINER_ID = $(e.currentTarget).data('container')
  const URL = OBJ.data('url')
  const MODAL = $(e.currentTarget).data('modal')
  const SCROLL_TO_OBJ = $(e.currentTarget).data('scrolltoelement')
  const CALLBACKS = $(e.currentTarget).data('callbacks')
  const FORM = OBJ.parents('form:first')
  const DATA = new FormData()
  let containerEl = $('#' + CONTAINER_ID)

  $.each($('input[type="file"]'), (i, file_elem) => {
    DATA.append($(file_elem).attr('name'), file_elem.files[0])
  })
  $.each(FORM.serializeArray(), (i, elem) => {
    DATA.append(elem.name, elem.value)
  })

  const ajaxCall = () => {
    $.ajax({
      url: URL,
      method: 'POST',
      contentType: false, // The content type used when sending data to the server.
      cache: false, // To unable request pages to be cached
      processData: false,
      data: DATA,
      beforeSend: () => {
        blockElement(containerEl)
      },
      success: response => {
        if (response.reload) {
          location.reload()
        } else if (response.goToUrl) {
          window.location.href = response.goToUrl
        } else if (response.success) {
          if (response.responseView && containerEl.length) {
            containerEl.replaceWith(response.responseView)
            containerEl.find('.js-ajaxLoadBox').each((i, obj) => {
              fillInvisibleContent($(obj))
            })
            if (SCROLL_TO_OBJ) {
              const EL = $('#' + SCROLL_TO_OBJ)
              if (EL && EL.length) {
                scrollToElement(EL)
              } else {
                scrollToElement(containerEl)
              }
            }
          }

          if (response.modalResponseView && MODAL) {
            openFoundationMessage(response.modalResponseView)
          }

          const methodsArr = CALLBACKS ? CALLBACKS.split(',') : []
          methodsArr.forEach(el => {
            const FUNC = window[el]; const RET_CALLBACK = FUNC(response)
          })
        }
        if (response.hasOwnProperty('flashMessage')) {
          flashMessage = response.flashMessage
        }

        reinitTooltip()
      },
      fail: (xhr, textStatus, errorThrown) => {
        openFoundationMessage(
          'An error has occurred , Please contact our office!'
        )
      },
      complete: () => {
        unblockElement(containerEl)
        if (flashMessage) {
          FlashMessage.showShortMessage(flashMessage)
        }
      }
    })
  }
  if (CONFIRMATION) {
    confirmation_dialog(ajaxCall, '', CONFIRMATION, BTN_TEXT)
  } else {
    ajaxCall()
  }
})

/**
 * Available data properties
 * data-url | REQUIRED | : ajax url to be called
 * data-container : dom element that contains checkboxes
 * data-emptymessage: message to be displayed in case that no item has been selected, def value: Please select at least one item!
 * data-modal: display result in modal popup
 * data-callbacks: list of js methods to call after ajax (comma separated list)
 * data-confirmation: display confirmation message (1-display)
 * data-confirmationmessage: confirmation message to be displayed if data-confirmation=1, def value 'Are you sure you want to perform selected action?'
 * data-confirmationbutton: Yes/Ok button label for confirmation dialog if data-confirmation=1, def value 'Submit'
 * data-confirmationtitle: confirmation title to be displayed if data-confirmation=1, def value 'Confirm'
 *
 */

$(document).on('click', '.js-multichoice-action', e => {
  e.preventDefault()

  const
    OBJ = $(e.currentTarget)
  const CONTAINER_ID = $(e.currentTarget).data('container')
  const EMPTY_MSG = getParameter(
    $(e.currentTarget).data('emptymessage'),
    'Please select at least one item!'
  )
  const URL = $(e.currentTarget).data('url')
  const CALLBACKS = $(e.currentTarget).data('callbacks')
  const CONFIRMATION = $(e.currentTarget).data('confirmation')
  const MODAL = $(e.currentTarget).data('modal')
  const CONFIRMATION_MSG = getParameter(
    $(e.currentTarget).data('confirmationmessage'),
    'Are you sure you want to perform selected action?'
  )
  const CONFIRMATION_BTN = getParameter(
    $(e.currentTarget).data('confirmationbutton'),
    'Submit'
  )
  const CONFIRMATION_TITLE = getParameter(
    $(e.currentTarget).data('confirmationtitle'),
    'Confirm'
  )
  const SCROLL_TO_OBJ = $(e.currentTarget).data('scrolltoelement')
  let flashMessage = null
  const containerEl = $('#' + CONTAINER_ID)
  const
    EXISTS_SELECTED = containerEl.find('.js-multi-choice-checkbox:checked').length > 0
  const DATA = new FormData()
  containerEl.find('.js-multi-choice-checkbox:checked').each((i, obj) => {
    DATA.append('selected_ids[]', $(obj).val())
  })

  if (!EXISTS_SELECTED) {
    FlashMessage.showErrorMessage(EMPTY_MSG)
    return false
  }

  const ajaxCall = () => {
    $.ajax({
      url: URL,
      method: 'POST',
      contentType: false, // The content type used when sending data to the server.
      cache: false, // To unable request pages to be cached
      processData: false,
      data: DATA,
      beforeSend: () => {
        blockElement(OBJ)
      },
      success: response => {
        if (response.reload) {
          location.reload()
        } else if (response.goToUrl) {
          window.location.href = response.goToUrl
        } else if (response.success) {
          if (response.responseView && containerElement.length) {
            containerElement.replaceWith(response.responseView)
            containerElement = $('#' + CONTAINER_ID)
            containerElement.find('.js-ajaxLoadBox').each((i, obj) => {
              fillInvisibleContent($(obj))
            })
            if (SCROLL_TO_OBJ) {
              const EL = $('#' + SCROLL_TO_OBJ)
              if (EL && EL.length) {
                scrollToElement(EL)
              } else {
                scrollToElement(containerElement)
              }
            }
          } else if (response.responseView && MODAL) {
            openFoundationMessage(response.responseView)
          }
          const methodsArr = CALLBACKS ? CALLBACKS.split(',') : []
          methodsArr.forEach(el => {
            const FUNC = window[el]; const RET_CALLBACK = FUNC(response)
          })
        }
        if (response.hasOwnProperty('flashMessage')) {
          flashMessage = response.flashMessage
        }

        reinitTooltip()
      },
      fail: (xhr, textStatus, errorThrown) => {
        openFoundationMessage(
          'An error has occurred , Please contact our office!'
        )
      },
      complete: () => {
        unblockElement(OBJ)
        if (flashMessage) {
          FlashMessage.showShortMessage(flashMessage)
        }
      }
    })
  }
  if (CONFIRMATION) {
    confirmation_dialog(ajaxCall, CONFIRMATION_TITLE, CONFIRMATION_MSG, CONFIRMATION_BTN)
  } else {
    ajaxCall()
  }
})

$(document).on('click', '.js-select-all', e => {
  e.preventDefault()

  const
    CONTAINER_ID = $(e.currentTarget).data('container')
  const CONTAINER = $('#' + CONTAINER_ID)
  const CHBOX = CONTAINER.find('.js-multi-choice-checkbox')
  const ALL_CHECKED = CHBOX.length === CONTAINER.find('.js-multi-choice-checkbox:checked').length

  CHBOX.prop('checked', !ALL_CHECKED)
})

$(document).on('click', '.js-ajax-chart-refresh', (e) => {
  e.preventDefault()
  const
    EL = $(e.currentTarget)
  const URL = EL.data('url')
  const RESULT_CONTAINER = getElement(EL.data('resultcontainer'))
  const FORM_CONTAINER = getElement(EL.data('formcontainer'))
  const FORM_DATA = EL.closest('form').serialize()
  const METHOD = EL.data('method') || 'POST'

  $.ajax({
    method: METHOD,
    url: URL,
    data: FORM_DATA,
    dataType: 'JSON',
    beforeSend: () => {
      EL.prop('disabled', true)
      blockElement(RESULT_CONTAINER)
    },
    success: (response) => {
      RESULT_CONTAINER.html('')
      RESULT_CONTAINER.append('<canvas></canvas>')
      if (response.hasOwnProperty('chart')) {
        FORM_CONTAINER.find('.error').removeClass('error')
        $('.help-text--error').remove()
        new Chart(RESULT_CONTAINER.find('canvas'), response.chart)
      } else {
        FORM_CONTAINER.html(response.responseView)
      }
      if (response.flashMessage) {
        FlashMessage.displayFlashMessages(response.flashMessage)
      }
    },
    complete: () => {
      EL.prop('disabled', false)
      unblockElement(RESULT_CONTAINER)
    }
  })
})

$(document).on('click', '.js-search-form-reset', e => {
    const element = $(e.currentTarget)
    fillInvisibleContent($('#' + element.data('resultcontainer')))
})


// Infinite scroll
/**
 * Available classes
 * js-infinite-scroll | REQUIRED | : must be added to the container element where results are loaded.
 * 
 * Available data properties
 * data-url | REQUIRED | : ajax url to be called.
 * data-maxresults | REQUIRED | : maximum number of results that a container should hold. Eg.: a feed container should load 167 results in segments of 20, a value for this attribute should be 167.
 * data-initially_loaded : if first page is loaded using some other functionality, this parameter set to 1 will not load first page but it will continue to load rest of pages on scroll.
 * 
 * Note for backend:
 * 'end' additional attribute from backend must be received to prevent attempting to load more than maximum results. 
*/
function setupInfiniteScroll(el){
  const URL = $(el).data('url') ? $(el).data('url').toString().trim() : undefined;
  const ROWS = $(el).data('rows') ? parseInt($(el).data('rows').toString().trim()) : undefined;
  const MAX_RESULTS = $(el).data('maxresults') ? $(el).data('maxresults').toString().trim() : undefined;
  const PAGE = 1;

  if(!URL) return;

  $(el).data('page', PAGE);
  $(el).data('initiated', true);

  if($(el).data('initially_loaded')) {
    onScrollInfinite();
    return;
  }

  $.ajax({
    method: 'REQUEST',
    url: MAX_RESULTS > 0 ? `${URL}/${PAGE}${ROWS ? `/${ROWS}` : ''}` : URL,
    beforeSend: () => {
      $(el).prop('disabled', true);
    },
    success: (data) => {
      $(el).html(data.responseView);
      if(!!data.end) $(el).removeClass('js-infinite-scroll');
    },
    fail: () => {
      openFoundationMessage("An error has occurred , Please contact our office!");
    },
    complete: () => {
      $(el).prop('disabled', false);
      $('.tabs').foundation();
      $(document).foundation();
      onScrollInfinite();
    }
  })
}

$('.js-infinite-scroll').each((i, el) => {
  setupInfiniteScroll(el);
});

$(document.body).on('touchmove', onScrollInfinite); // mobile
$(window).on('scroll', onScrollInfinite); // desktop

function onScrollInfinite() {
  $(".js-infinite-scroll").each((i, el) => {
    if(!!$(el).prop("disabled") || !$(el).is(':visible')) return;
    const elHeight = $(el).height();
    const elTopDistance = $(el).offset().top;
    const windowHeight = $(window).height();
    const windowTopDistance = $(window).scrollTop();
    const windowBottomDistanceFromTop = windowHeight + windowTopDistance;
    const scrollThreshold = elHeight / 2 + elTopDistance;

    if(windowBottomDistanceFromTop > scrollThreshold && $(el).data("initiated")) {
      // Loading on scroll activation
      const URL = $(el).data("url") ? $(el).data("url").toString().trim() : undefined;
      const ROWS = $(el).data("rows") ? parseInt($(el).data("rows").toString().trim()) : undefined;
      const PAGE = parseInt($(el).data("page").toString().trim()) + 1;
      const MAX_RESULTS = $(el).data("maxresults") ? $(el).data("maxresults").toString().trim() : undefined;

      let finalUrl = `${URL}/${PAGE}${ROWS ? `/${ROWS}` : ""}`;

      if (!!window.location.search) {
          finalUrl = finalUrl + window.location.search;
      }

      if((PAGE - 1) * ROWS > MAX_RESULTS || MAX_RESULTS == 0 || !URL) return;

      $.ajax({
        method: "GET",
        url: finalUrl,
        beforeSend: () => {
          $(el).prop("disabled", true);
            $(el).append("<div class='loading-search-results'></div>");
            // Checking for initial load on Authors and Users card when user is on this pages and reloads page
            // TODO: implement some better solution if there is any
            if(typeof blockElement === 'function') blockElement($('.loading-search-results')); // display loading circle
        },
        success: (data) => {
          $(el).append(data.responseView);
          $(el).data("page", PAGE);
          if (!!data.end) $(el).removeClass("js-infinite-scroll");

          $('.js-search-result-wrapper').each(function () {
              if ($(this).find('.search-item').length > 0) {
                $(this).find('.search-zero-results').hide();
              }
          });

          reinitTooltip()
          $(document).foundation();
        },
        complete: () => {
          $(el).prop("disabled", false);
          unblockElement($('.loading-search-results'));
          $('.loading-search-results').remove();
          $('.tabs').foundation();
        },
      });
    }
  });
}

function InfiniteScroll(container) {
  this.container = container;
  this.loading = false;
  this.currentPage = 1;
  this.lastId = '';

  this.initialize = function() {
    this.loadMoreData();
    this.events();
  }

  this.events = function() {
    if(!this.container.length) return;

    if (container.data('messagedialog')) {
      container.on('scroll touchmove', this.onMessageDialogScroll.bind(this));
    } else {
      $(window).on('scroll touchmove', this.onScroll.bind(this));
    }
  }

  this.onScroll = function() {
    if (!this.loading && $(window).scrollTop() + $(window).height() >= $(document).height() - 100) {
      this.loadMoreData(false);
    }
  }

  this.onMessageDialogScroll = function () {
    if (!this.loading && container.height() - container.scrollTop() >= container[0].scrollHeight - 50) {
      this.loadMoreData(false);
    }
  }

  this.unblockElement = function(element) {
    if (element) {
      element.unblock();
    }
  }

  this.blockElement = function(element) {
    $.blockUI.defaults = {
      message: `<div class="loader-wrap">
                  <div class="loader">
                    <svg class="round" viewBox="25 25 50 50">
                      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" />
                    </svg>
                  </div>
                </div>`,
      baseZ: 9800
    };
    $.blockUI.defaults.css = {};

    if(element) {
      const height = element.outerHeight();
      $.blockUI.defaults.css = { 'min-height': height + 'px', 'z-index': 'inherit' };
      element.block();
      $('.loader-wrap').css({ height: height + 'px' });
    }
  }

  this.initialValue = function() {
    this.currentPage = 1;
    this.loading = false;
  }

  this.loadMoreData = function(initialLoad = true) {
    if (this.loading) return;
    this.loading = true;
    const infiniteScrollEl = this.container;
    if (infiniteScrollEl.length) {
      infiniteScrollEl.each((i, el) => {
        const container = $(el);
        const url = container.data('url');
        const containerId = container.data('container');
        const callbacks = container.data('callbacks');
        const newsFeed = container.data('newsfeed');
        const messageDialog = container.data('messagedialog');

        let finalUrl = `${url}/${this.currentPage}`;
        if (messageDialog) {
          finalUrl = `${url}/${this.lastId}`;
        }
        if(newsFeed) {
          $('.feed-item').removeClass('feed-item--hidden');
        }

        $.ajax({
          url: finalUrl,
          method: 'GET',
          dataType: 'json',
          beforeSend: () => {
            // Display loading circle
            $(el).append("<div class='loading-search-results'></div>");
            this.blockElement($('.loading-search-results'));
          },
          success: (response) => {
            const responseView = response.responseView;
            const responseContainer = $(`#${containerId}`);
            if(initialLoad && !messageDialog) {
              responseContainer.html(responseView);
            } else {
              responseContainer.append(responseView);
            }

            this.currentPage++;
            if (messageDialog) {
              this.lastId = response.last_id;
            }
            this.loading = false;

            if (!responseView) {
              // No more items to load, stop infinite scrolling
              this.loading = true;

              if(initialLoad && $('#js-empty-feed') && !messageDialog) $('#js-empty-feed').show();
              if(!initialLoad && $('#js-end-feed') && !messageDialog) $('#js-end-feed').show();
            }

            if (callbacks) {
              callbacks.split(',').forEach(callback => {
                if (window[callback] && typeof window[callback] === 'function') {
                  window[callback]();
                }
              });
            }
            $(document).foundation();
          },
          complete: () => {
            this.unblockElement($('.loading-search-results'));
            $('.loading-search-results').remove();
            if(newsFeed && initialLoad) {
              // Remove skeleton after cache is ready
              $('#js-loading-cache').remove();
              $('.feed__wrap').removeClass('hidden');
              $('.feed__wrap').children().removeClass('hidden');
            }
          },
          error: (xhr, status, error) => {
            console.log(error);
            this.loading = true;
          }
        });
      });
    }
  }
}

function checkIfCacheIsReady() {
  const element = $('#newsfeed_wrap');
  const cacheUrl = element.data('cache-url');
  $('#js-loading-cache').show();
  $.ajax({
    url: cacheUrl,
    method: 'GET',
    success: function(response) {
      if (!response.status){
        setTimeout(function() {
          checkIfCacheIsReady();
        }, 1500);
      } else {
        infiniteScroll.initialize();
      }
    }
  });
}

let infiniteScroll = new InfiniteScroll($('.js-new-infinite-scroll'));

if($('#newsfeed_wrap').data('cache-url')) {
  // Check if page needs to load cache before infinite scroll
  checkIfCacheIsReady();
} else {
  infiniteScroll.initialize();
}

$(document).on('change', '.js-ajax-select', function () {
  const select = $(this);
  const requestData = {};
  const callback = select.data('callback');
  requestData[select.attr('name')] = select.val();

  $.ajax({
    url: select.data('url'),
    method: 'POST',
    data: requestData,
    success: function (data) {
      if (data.hasOwnProperty("flashMessage")) {
        FlashMessage.showShortMessage(data.flashMessage);
      }
      if (callback && window[callback] && typeof window[callback] === 'function') {
        window[callback](select.val());
      }
    }
  });
});
